import React, {useEffect, useState} from "react"
import _ from "lodash"
import qs from "qs"
import {AppSync} from "../../libraries/appsync"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import ProductList from "../../components/ProductList"
import {ButtonTop} from "../../components/Button"
import PageNavigation from "../../components/PageNavigation"
import PageTitle from "../../components/PageTitle"
import Pagination from "../../components/Pagination"
import GroupSelect from "../../components/GroupSelect"
import {Preview, cryptString} from "../../libraries/preview";

export default ({location}) => {
  const initialPage = 1
  const pageSize = 12

  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [categories, setCategories] = useState({initial: "", c1: {}, c2: {}, keyword: ""})
  const [categoryText, setCategoryText] = useState("")
  const [currentPage, setCurrentPage] = useState(initialPage)

  const pageChangeHandler = p => setCurrentPage(p)

  let originalProducts = []

  useEffect(() => {
    setLoading(true)
    Preview(cryptString(location)).then(customDateTime => {
      const productQuery = customDateTime ? `(preview:"${customDateTime}")` : ""

      AppSync.post("/", {
        query: `
          query products {
            listProducts${productQuery} {
              Hin
              Title
              Release
              OrderDateFrom
              OrderDateTo
              Price
              NetPrice
              Status
              PreType
              PreFromDate
              PreToDate
              ecCategory {
                member
                category1
                category2
              }
              ecImage
              ecVisibleBeginDate
              ecReleaseDate
              ecSellingStatus
              ecSort
              ecBody
              ecItemName
            }
            listGroups {
              items {
                hinban
                parent
                child
                groupName
              }
            }
            listCategories {
              value {
                category1_name
                category2_name
                category1 {
                  categoryKey
                  categoryName
                  sort
                }
                category2 {
                  categoryKey
                  categoryName
                  sort
                }
              }
            }
          }
        `
      }).then(res => {
        // ソート順:降順、公開日時:降順、品番:昇順
        const products = _.orderBy(
          _.get(res.data, "data.listProducts"),
          [
            s => s.ecSort === "11111111111" ? 0 : parseInt(s.ecSort),
            v => v.ecVisibleBeginDate,
            h => h.Hin,
          ],
          ["desc", "desc", "asc"]
        )
        const children = _.map(_.filter(_.get(res.data, "data.listGroups.items"), g => g.parent != null && g.child == null), p => p.hinban)
        const parentProducts = _.filter(products, p => children.indexOf(p.Hin) === -1)
        // 紐づく商品グループがある場合は取得挿入
        const groups = []
        _.get(res.data, "data.listGroups.items", []).forEach(group => {
          groups[group.hinban] = group.groupName
        })
        for (const productIndex in products) {
          products[productIndex]["groupName"] = _.get(groups, products[productIndex]["Hin"], undefined)
        }

        // カテゴリタイトル
        const c1Title = _.get(res.data, "data.listCategories.value.category1_name")
        const c2Title = _.get(res.data, "data.listCategories.value.category2_name")

        // カテゴリリスト ソート順:降順
        const c1List = _.orderBy(
          _.get(res.data, "data.listCategories.value.category1"),
          [c => c.sort],
          ["desc"]
        )
        const c2List = _.orderBy(
          _.get(res.data, "data.listCategories.value.category2"),
          [c => c.sort],
          ["desc"]
        )

        const queryString = qs.parse(window.location.search, {ignoreQueryPrefix: true})
        const initialCategory = _.get(queryString, "c", "")

        setCategories({
          initial: initialCategory,
          c1Title: c1Title,
          c2Title: c2Title,
          c1List: c1List,
          c2List: c2List,
          c1Value: window.sessionStorage.getItem("C1Value"),
          c2Value: window.sessionStorage.getItem("C2Value"),
          keyword: window.sessionStorage.getItem("keywordValue"),
          callback: groupSelectCallback
        })

        originalProducts = parentProducts

        // 全カテゴリ保持用
        setAllProducts(products)
        if (initialCategory.length > 0) {
          const c1Found = c1List.find(c => c.categoryKey === initialCategory)
          const c2Found = c2List.find(c => c.categoryKey === initialCategory)
          if (c1Found) {
            window.sessionStorage.setItem("C1Value", c1Found.categoryKey)
            window.sessionStorage.setItem("C2Value", "")
          }
          if (c2Found) {
            window.sessionStorage.setItem("C1Value", "")
            window.sessionStorage.setItem("C2Value", c2Found.categoryKey)
          }

          groupSelectCallback({
            c1: c1Found ? {key: c1Found.categoryKey, name: c1Found.categoryName} : {key: "", name: ""},
            c2: c2Found ? {key: c2Found.categoryKey, name: c2Found.categoryName} : {key: "", name: ""}
          }, originalProducts)
        } else if (window.sessionStorage.getItem("C1Value") || window.sessionStorage.getItem("C2Value") || window.sessionStorage.getItem("keywordValue")) {
          const c1Found = c1List.find(c => c.categoryKey === window.sessionStorage.getItem("C1Value"))
          const c2Found = c2List.find(c => c.categoryKey === window.sessionStorage.getItem("C2Value"))
          const keywordValue = window.sessionStorage.getItem("keywordValue")
          groupSelectCallback({
            c1: c1Found ? {key: c1Found.categoryKey, name: c1Found.categoryName} : {key: "", name: ""},
            c2: c2Found ? {key: c2Found.categoryKey, name: c2Found.categoryName} : {key: "", name: ""},
            keyword: keywordValue
          }, originalProducts)
      
        } else {
          setProducts(parentProducts)
        }

        //ページ保持用
        const prodcutPageValue = window.sessionStorage.getItem("productPageValue")
        if (prodcutPageValue) {
          setCurrentPage(parseInt(prodcutPageValue))
        } else {
          setCurrentPage(1)
        }

        setLoading(false)
      })
    })
  }, [])

  const checkCategory = (str, keyword) => {
    if (str !== null) {
      var category = JSON.parse(str)
      if (category.selected !== undefined) {
        if (category.selected.L !== undefined) {
          for (var i = 0 ; i < category.selected.L.length; i++) {
            if (category.selected.L[i].M !== undefined) {
              if (category.selected.L[i].M.categoryName !== undefined) {
                if (category.selected.L[i].M.categoryName.S !== undefined) {
                  if (category.selected.L[i].M.categoryName.S.toLowerCase().includes(keyword.toLowerCase())) {
                    return true;
                  }
                }
              }
            }
          }
        }
      }
    }
    return false;
  }

  const groupSelectCallback = (groups, products) => {
    let filteredProducts = []
    if (products === undefined) {
      filteredProducts = originalProducts
    } else {
      filteredProducts = originalProducts
    }

    // カテゴリ未選択
    if (groups["c1"].key.length === 0 && groups["c2"].key.length === 0) {
      setCategoryText("")
      window.sessionStorage.setItem("productPageValue", 1)
      setCurrentPage(1)
    } else {
      // カテゴリが1つ以上選択されている
      filteredProducts = _.filter(originalProducts, p => {
        let hit1 = false
        let hit2 = false
        let text = ["", ""]

        if (groups["c1"].key.length === 0) {
          hit1 = true
        } else {
          text[0] = groups["c1"].name
          const c = JSON.parse(_.get(p, "ecCategory.category1", ""))
          if (c !== null && 'selected' in c) {
            c.selected.L.forEach((option) => {
              if (option.M.categoryKey.S === groups["c1"].key) {
                hit1 = true
              }
            })
          } else {
            if (_.get(c, "categoryKey.S") === groups["c1"].key) {
              hit1 = true
            }  
          }
        }

        if (groups["c2"].key.length === 0) {
          hit2 = true
        } else {
          text[1] = groups["c2"].name
          const c = JSON.parse(_.get(p, "ecCategory.category2", ""))
          if (c !== null && 'selected' in c) {
            c.selected.L.forEach((option) => {
              if (option.M.categoryKey.S === groups["c2"].key) {
                hit2 = true
              }
            })
          } else {
            if (_.get(c, "categoryKey.S") === groups["c2"].key) {
              hit2 = true
            }
          }
        }

        const textTrim = _.trim(text.join(" / "), "/ ")
        setCategoryText(textTrim.length === 0 ? "" : `${textTrim} の検索結果`)
        setCurrentPage(1)
        
        return hit1 && hit2
      })
    }

    //キーワード検索
    if ('keyword' in groups && groups.keyword !== '') {
      filteredProducts = filteredProducts.filter((product) => {
        const check1 = checkCategory(product.ecCategory.category1, groups.keyword)
        const check2 = checkCategory(product.ecCategory.category2, groups.keyword)
        return product.Title.toLowerCase().includes(groups.keyword.toLowerCase()) ||
        product.ecBody.toLowerCase().includes(groups.keyword.toLowerCase()) ||
        product.ecItemName.toLowerCase().includes(groups.keyword.toLowerCase()) ||
        (check1) ||
        (check2)
      })
    }

    setProducts(filteredProducts)
  }

  return (
    <Layout>
      <SEO title="ALL"/>
      <section className="constraint">
        <div className="group-select-wrap">
          <PageTitle>Items</PageTitle>
          <GroupSelect group={categories} products={products} allProducts={allProducts} />
        </div>
        <p className="group-text">{categoryText}</p>
        <ProductList items={products.slice((currentPage - 1) * pageSize, currentPage * pageSize)} loading={loading}/>
        <Pagination items={products} initialPage={currentPage} pageSize={pageSize} changeHandler={pageChangeHandler}/>
        <PageNavigation>
          <ButtonTop/>
        </PageNavigation>
      </section>
    </Layout>
  )
}