import React, {useEffect, useRef, useState} from "react"
import _ from "lodash";
import classNames from "classnames";
import FilterImg from "../../images/Main/filter.svg"

export default ({group, products, allProducts}) => {
  const [modal, setModal] = useState(false)
  const [c1value, setC1Value] = useState("")
  const [c2value, setC2Value] = useState("")
  const [keywordValue, setKeywordValue] = useState("")
  const [c1OptionTag, setC1OptionTag] = useState([])
  const [c2OptionTag, setC2OptionTag] = useState([])
  const group1Ref = useRef(null)
  const group2Ref = useRef(null)
  const keywordRef = useRef(null)

  const changeHandler = event => {
    group.callback({
      c1: {
        key: group1Ref.current.value,
        name: group1Ref.current.options[group1Ref.current.selectedIndex].text,
      },
      c2: {
        key: group2Ref.current.value,
        name: group2Ref.current.options[group2Ref.current.selectedIndex].text,
      },
      keyword: keywordRef.current.value
    }, allProducts)

    // ローカルに値保存
    window.sessionStorage.setItem("C1Value", group1Ref.current.value)
    window.sessionStorage.setItem("C2Value", group2Ref.current.value)
    window.sessionStorage.setItem("keywordValue", keywordRef.current.value)
    setKeywordValue(keywordRef.current.value)
    setC1Value(group1Ref.current.value)
    setC2Value(group2Ref.current.value)
    // 変更時、ページは1に
    window.sessionStorage.setItem("productPageValue", 1)
  }

  const createOptionTag = (groupList, usedKeyList) => groupList
    .filter(c => usedKeyList.indexOf(c.categoryKey) !== -1)
    .map(c => <option key={c.categoryKey} value={c.categoryKey}>{c.categoryName}</option>)

  useEffect(() => {
    // 使用していないカテゴリはリスト表示させない
    const usedCategoryKeyList = []
    allProducts.forEach(p => {
      const category1 = JSON.parse(p.ecCategory.category1)
      const category2 = JSON.parse(p.ecCategory.category2)

      if(category1 !== null) {
        if('selected' in category1) {
          //新複数カテゴリ用処理
          category1.selected.L.forEach((option) =>  usedCategoryKeyList.push(option.M.categoryKey.S))
        } else {
          //旧単一カテゴリ用処理
          const c1Key = _.get(JSON.parse(_.get(p, "ecCategory.category1", "{}")), "categoryKey.S", null)
          if (c1Key !== null) usedCategoryKeyList.push(c1Key)
        }  
      }
      if(category2 !== null) {
        if('selected' in category2) {
          //新複数カテゴリ用処理
          category2.selected.L.forEach((option) =>  usedCategoryKeyList.push(option.M.categoryKey.S))
        } else {
          //旧単一カテゴリ用処理
          const c2Key = _.get(JSON.parse(_.get(p, "ecCategory.category2", "{}")), "categoryKey.S", null)
          if (c2Key !== null) usedCategoryKeyList.push(c2Key)
        }          
      }

    })
    const uniqCategoryKeyList = _.uniq(usedCategoryKeyList)

    if (group.initial.length > 0) {
      setC1Value(group.initial)
      setC2Value(group.initial)  
    } else if (group.c1Value || group.c2Value) {
      setC1Value(group.c1Value)
      setC2Value(group.c2Value)  
    }
    setKeywordValue(group.keyword)
    setC1OptionTag(createOptionTag(_.get(group, "c1List", []), uniqCategoryKeyList))
    setC2OptionTag(createOptionTag(_.get(group, "c2List", []), uniqCategoryKeyList))
  }, [group, products, allProducts])

  return (
    <>
      <div className="group-select">
        <div className={classNames("modal", {"modal-active": modal})}>
          <div>
            <div>
              <div>
                <div className="input-keyword">
                  <input ref={keywordRef} placeholder="検索" defaultValue={keywordValue} onChange={changeHandler} />
                </div>
                {!_.isEmpty(c1OptionTag) && (
                  <div className="select-with-arrow">
                    <select ref={group1Ref} onChange={changeHandler} defaultValue={c1value}>
                      <option value="">{_.get(group, "c1Title", "")}</option>
                      {c1OptionTag}
                    </select>
                  </div>
                )}
                {!_.isEmpty(c2OptionTag) && (
                  <div className="select-with-arrow">
                    <select ref={group2Ref} onChange={changeHandler} defaultValue={c2value}>
                      <option value="">{_.get(group, "c2Title", "")}</option>
                      {c2OptionTag}
                    </select>
                  </div>
                )}
              </div>
              <div>
                {/*<button type="button" onClick={() => setModal(false)}>閉じる</button>*/}
                {/*<button type="button">絞り込む</button>*/}
                <button type="button" onClick={() => setModal(false)}>決定</button>
              </div>
            </div>
          </div>
        </div>
        {(!_.isEmpty(group.c1List) || !_.isEmpty(group.c2List)) && (
          <button type="button" style={{backgroundImage: `url(${FilterImg})`}} onClick={() => setModal(true)}>
            絞り込み
          </button>
        )}
      </div>
    </>
  )
}